<template>
	<r-data-list
		:headdata="headdata"
		:listdata="listData.data"
		v-if="loaded"
		:opt="opts"
		:searchfilter="searchfilter"
		v-model:qu="q"
	>
		<template v-slot:titleextrabuttons>
			<r-input v-model="logcount" label="Log Sayısı" width="15%" />
			<r-btn @click="getLogs()">Listele</r-btn>
		</template>
	</r-data-list>
</template>
<script>
import { onBeforeMount, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { trToUpper, mxDate } from '@/mix/global'
export default {
	setup() {
		const store = useStore()
		const listData = ref({})
		const loaded = ref(false)
		const q = ref('')
		const logcount = ref(5000)
		const searchfilter = v => {
			return (
				trToUpper(v.id).includes(trToUpper(q.value)) ||
				trToUpper(v.detail).includes(trToUpper(q.value)) ||
				mxDate('dd-mm-yy-tt', v.createDate, '.').includes(q.value)
			)
		}
		const headdata = computed(() => {
			return [
				{
					title: 'Log ID',
					style: { width: '8%' },
					key: 'id',
				},
				{
					title: 'Detay',
					style: { width: '77%' },
					key: 'detail',
				},
				{
					title: 'Tarih',
					style: { width: '15%' },
					key: 'createDate',
					cval: v => {
						return mxDate('dd-mm-yy-tt', v.createDate, '.')
					},
				},
			]
		})
		const opts = ref({
			title: 'Loglar',
			count: true,
		})
		const getList = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'logs/getalllogs',
				params: {
					page: 1,
					size: logcount.value,
					order: 1,
				},
			})
			return list
		}
		const getLogs = async () => {
			listData.value = await getList()
		}
		onBeforeMount(async () => {
			listData.value = await getList()
			loaded.value = true
		})
		return {
			headdata,
			opts,
			listData,
			loaded,
			q,
			searchfilter,
			logcount,
			getLogs,
		}
	},
}
</script>
